<script>
import ToggleableListItem from '@/components/general/ToggleableListItem'
import ModalIntegrations from '../../../components/general/ModalIntegrations.vue'
import ConfirmationModal from '../../../components/general/ConfirmationModal.vue'
import Loading from '@/components/general/Loading.vue'
export default {
  name: 'Integrations',
  components: { ToggleableListItem, ModalIntegrations, ConfirmationModal, Loading },
  data () {
    return {
      getIntegrationsCompany: {},
      showModal: false,
      showErrorModal: false,
      showConfirmInstall: false,
      showRemoveModal: false,
      removeModalProps: {},
      selectedTitle: '',
      haveNotification: false,
      haveConvenia: false,
      haveMindsight: false,
      haveYube: false,
      isReady: false,
      integrationSelected: [],
      titleErrorModal: this.$t('integration.warning.custom.title'),
      descriptionErrorModal: '',
      data: [],
      item: {
        image: '',
        title: '',
        description: '',
        tips: '',
        fisrtTitleConfiguration: '',
        firstDescriptionConfiguration: '',
        secondTitleConfiguration: '',
        secondDescriptionConfiguration: ''
      }
    }
  },
  created () {
    this.onStart()
  },
  methods: {
    onStart () {
      this.getIntegrationsCompany = { integrations: [] }
      this.$store.dispatch('attemptGetIntegrations').then((data) => {
        this.getIntegrationsCompany = data
        this.getIntegrations()
        this.isReady = true
      })
    },
    getIntegrations () {
      const integrations = ['convenia', 'mindsight', 'yube', 'notification']
      for (const itg of integrations) {
        if (itg !== 'notification') {
          this.$store.dispatch('attemptGetCompanyAutomations', itg)
            .then(({ data }) => {
              if (itg === 'convenia') this.haveConvenia = data.companyAutomations
              if (itg === 'yube') this.haveYube = data.companyAutomations
              if (itg === 'mindsight') this.haveMindsight = data.companyAutomations
            })
        } else {
          this.haveNotification = this.getIntegrationsCompany.static.hiringnotification
        }
      }
    },
    getIntegration (value, nameIntegration) {
      if (value === 'hiringnotification') {
        if (this.haveNotification) this.integrationSelected = [{ name: this.haveNotification, _id: this.haveNotification, token: this.haveNotification }]
      } else {
        switch (nameIntegration) {
          case 'convenia':
            this.integrationSelected = this.haveConvenia
            break
          case 'yube':
            this.integrationSelected = this.haveYube
            break
          case 'mindsight':
            this.integrationSelected = this.haveMindsight
            break
          default:
            break
        }
      }
    },
    handleShowModal (value) {
      this.showModal = !this.showModal
      if (this.showModal === true) {
        this.selectedTitle = value
        this.item.image = '/assets/images/Integration/' + value[0].toUpperCase() + value.substr(1) + '.png'
        if (value === 'notification') value = 'hiringnotification'
        this.item.title = 'integration.' + value + '.title'
        this.item.description = 'integration.' + value + '.description'
        this.item.tips = 'integration.' + value + '.info'
        this.item.fisrtTitleConfiguration = 'integration.' + value + '.first.label'
        this.item.secondTitleConfiguration = value !== 'hiringnotification' ? 'integration.' + value + '.second.label' : ''
        this.integrationSelected = []
        if (value === 'convenia' && this.haveConvenia.length > 0) this.getIntegration(value, 'convenia')
        if (value === 'yube' && this.haveYube.length > 0) this.getIntegration(value, 'yube')
        if (value === 'mindsight' && this.haveMindsight.length > 0) this.getIntegration(value, 'mindsight')
        if (value === 'hiringnotification') this.getIntegration(value, 'hiringnotification')
      }
    },
    showConfirmationModal () {
      this.showConfirmModal = !this.showConfirmModal
      this.showModal = !this.showModal
      this.getIntegrations()
    },
    handleIntegration (val) {
      const data = {
        title: this.selectedTitle,
        name: val.firstInput,
        token: this.selectedTitle === 'notification' ? val.firstInput : val.secondInput
      }

      if (this.selectedTitle === 'notification') {
        this.$store.dispatch('attemptPutNotification', data).then(() => {
          this.onStart()
        })
      } else {
        this.$store.dispatch('attemptSaveIntegrations', data).then(data => {
          this.handleShowConfirmInstall()
        }).catch((err) => {
          if (err.response.data.msg === 'Error validating credentials') {
            this.descriptionErrorModal = this.$t('integration.warning.token.invalid')
            this.handleErrorModal()
          } else {
            this.descriptionErrorModal = this.$t('integration.warning.token.already')
            this.handleErrorModal()
          }
        })
      }
    },
    deleteIntegration (value) {
      if (value.description === this.$t('admin.center.integrations:ads.notification')) {
        this.$store.dispatch('attemptDeleteNotification').then(() => {
          this.onStart()
          this.showRemoveModal = !this.showRemoveModal
        })
      } else {
        const data = {
          integrationName: value.description.toLowerCase(),
          companyAutomationId: value.id
        }
        this.$store.dispatch('attemptDeleteIntegration', data).then(() => {
          this.onStart()
          this.showRemoveModal = !this.showRemoveModal
        })
      }
    },
    handleErrorModal () {
      this.showErrorModal = !this.showErrorModal
    },
    showConfirmRemoveModal (event) {
      if (event !== undefined) {
        this.removeModalProps = {
          id: event.integration_id,
          description: this.$t('admin.center.integrations:ads.' + this.selectedTitle)
        }
      }
      this.showRemoveModal = !this.showRemoveModal
      this.showConfirmationModal()
    },
    handleShowConfirmInstall () {
      this.showConfirmInstall = !this.showConfirmInstall
      this.onStart()
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady" />
    <div class="integrations--container" v-else>
      <p class="integrations--title">{{ $t('admin.center.integrations:description') }}</p>
      <toggleable-list-item @showModalComponent="handleShowModal('notification')"
        v-if="getIntegrationsCompany.integrations.includes('hiringnotification') !== -1"
        :item="{ title: $t('admin.center.integrations:ads.notification'), description: $t('admin.center.integrations:ads.notification.description'), image: '/assets/images/Integration/Notification.png', inputValue: haveNotification !== null, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="handleShowModal('convenia')"
        v-if="getIntegrationsCompany.integrations.includes('convenia') !== -1"
        :item="{ title: $t('admin.center.integrations:ads.convenia'), description: $t('admin.center.integrations:ads.convenia.description'), image: '/assets/images/Integration/Convenia.png', inputValue: haveConvenia.length > 0, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="handleShowModal('yube')"
        v-if="getIntegrationsCompany.integrations.includes('yube') !== -1"
        :item="{ title: $t('admin.center.integrations:ads.yube'), description: $t('admin.center.integrations:ads.yube.description'), image: '/assets/images/Integration/Yube.png', inputValue: haveYube.length > 0, isDisabled: true }">
      </toggleable-list-item>
      <toggleable-list-item @showModalComponent="handleShowModal('mindsight')"
        v-if="getIntegrationsCompany.integrations.includes('mindsight') !== -1"
        :item="{ title: $t('admin.center.integrations:ads.mindsight'), description: $t('admin.center.integrations:ads.mindsight.description'), image: '/assets/images/Integration/Mindsight.png', inputValue: haveMindsight.length > 0, isDisabled: true }">
      </toggleable-list-item>
      <confirmation-modal :show="showErrorModal" :title="titleErrorModal" :description="descriptionErrorModal"
        :confirmText="$t('global:ok')" :disableCancelBtn="true" :btnConfirmText="false" @confirm="handleErrorModal()" />
      <confirmation-modal :show="showRemoveModal" :title="$t('integration.linkedin.delete.modal.title')"
        :description="$t('integration.linkedin.delete.modal.description') + ' (' + removeModalProps.description + ') ?'"
        :confirmText="$t('global:ok')" :disableCancelBtn="true" :btnConfirmText="false" @cancel="showConfirmRemoveModal()"
        @confirm="deleteIntegration(removeModalProps)" />
      <confirmation-modal :show="showConfirmInstall" :title="$t('integration.warning.custom.success')"
        :description="$t('integration.warning.custom.wait')" :confirmText="$t('global:ok')" :disableCancelBtn="true"
        :btnConfirmText="false" @cancel="handleShowConfirmInstall()" @confirm="handleShowConfirmInstall()" />
      <ModalIntegrations :show="showModal"
        @close="handleShowModal()" @add="val => handleIntegration(val)" @remove="event => showConfirmRemoveModal(event)"
        :item="{ title: $t(item.title), description: $t(item.description), image: $t(item.image), tips: $t(item.tips).split('$'), dataToken: integrationSelected, firstLabel: $t(item.fisrtTitleConfiguration), secondLabel: $t(item.secondTitleConfiguration) }">
      </ModalIntegrations>
      <confirmation-modal :show="false"
        :title="$t('integration.warning.test.title')" :description="$t('integration.warning.test')"
        :confirmText="$t('global:ok')" :disableCancelBtn="true" :btnConfirmText="false" @cancel="showConfirmationModal()"
        @confirm="showConfirmationModal()" />

        <!-- <ModalIntegrations :show="getIntegrationsCompany.status === 'regular' ? showModal : false"
        @close="handleShowModal()" @add="val => handleIntegration(val)" @remove="event => showConfirmRemoveModal(event)"
        :item="{ title: $t(item.title), description: $t(item.description), image: $t(item.image), tips: $t(item.tips).split('$'), dataToken: integrationSelected, firstLabel: $t(item.fisrtTitleConfiguration), secondLabel: $t(item.secondTitleConfiguration) }">
      </ModalIntegrations>
      <confirmation-modal :show="(getIntegrationsCompany.status !== 'regular' && showModal)"
        :title="$t('integration.warning.test.title')" :description="$t('integration.warning.test')"
        :confirmText="$t('global:ok')" :disableCancelBtn="true" :btnConfirmText="false" @cancel="showConfirmationModal()"
        @confirm="showConfirmationModal()" /> -->
    </div>
  </div>
</template>
<style lang="scss">
.integrations--container {
  .integrations--title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A0A0A0;
  }
}
</style>
